import { render, staticRenderFns } from "./EditNonSurgicalWeightLoss.vue?vue&type=template&id=df42ff7c&scoped=true&"
import script from "./EditNonSurgicalWeightLoss.vue?vue&type=script&lang=js&"
export * from "./EditNonSurgicalWeightLoss.vue?vue&type=script&lang=js&"
import style1 from "./EditNonSurgicalWeightLoss.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "df42ff7c",
  null
  
)

export default component.exports