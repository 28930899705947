<template>
  <div>
    <!-- Top Navigation -->
    <div class="ml-4">
      <back-button/>
    </div>
    <!-- Top Navigation Finished-->
    <div class="checklist-template-header pl-4 pr-4 pt-4">
      <h1 class="">Add New Non Surgical Weight Loss</h1>
    </div>
    <non-surgical-weight-loss-form
      button-text="Save"
      :loaders="loaders.nonSurgicalWeightLoss"
      @storeNonSurgicalWeightLoss="storeNonSurgicalWeightLoss"
    />
  </div>
</template>

<script>
import NonSurgicalWeightLossForm from "./NonSurgicalWeightLossForm";
import BackButton from "@/components/Router/BackButton";
import generateErrorMessageMixin from "@/mixins/generateErrorMessageMixin";
export default {
  components :{
    NonSurgicalWeightLossForm,BackButton
  },
  name: "StoreNonSurgicalWeightLoss",
  mounted() {
  },
  data() {
    return {
      loaders: {
        nonSurgicalWeightLoss : false,
      },
      response: {
      },
    }
  },
  mixins: [
    generateErrorMessageMixin
  ],
  methods: {

    storeNonSurgicalWeightLoss(event) {
      let vm = this;
      vm.loaders.nonSurgicalWeightLoss = true
      var formData = new FormData();
      _.each(event, (value, key) => {
        formData.append(key, value);
      });

      const config = {
        headers: { 'content-type': 'multipart/form-data' }
      };

      axios.post(this.$store.getters.getBaseUrl + `/api/content/non-surgical-weight-loss/store`, formData, {config}).then(response => {
        vm.$store.commit('toggleSessionCheck', true)
        vm.$store.commit('updateSessionTime', 0)
        vm.$notify.success({
          title: 'Success',
          message: 'Non Surgical Weight Loss created successfully.'
        });
        formData = '';
        vm.$router.back();
      }).catch(error => {
        let message = error.response ? vm.generateErrorMessage(error.response.data.errors)
          : 'Something went wrong please try again in few minutes.';
        vm.$notify.error({
          title: 'Non Surgical Weight Loss',
          dangerouslyUseHTMLString: true,
          message: message
        });
      }).finally(() => vm.loaders.nonSurgicalWeightLoss = false)
    },
  },

}
</script>

<style scoped>

</style>
