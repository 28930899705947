<template>
  <div>
    <!-- Top Navigation -->
    <div class="ml-4 pl-3">
      <back-button/>
    </div>
    <div class="container-fluid d-flex justify-content-between mt-4 mb-4 row">
      <h1 class="ml-3 d-inline-block mt-2">View Non Surgical Weight Loss Detail</h1>
      <router-link class="router-link pr-2" :to="{name: 'EditNonSurgicalWeightLoss' , params: { id: apiParams.nonSurgicalWeightLossId } }">
        <base-button type="secondary" class="pt-2 pb-2 font-weight-normal custom-button">
          Edit
        </base-button>
      </router-link>
    </div>
    <div class="mt-1 row px-3 mx-0">
      <div class="col-6 content-section-card-width">
        <card class="no-border-card equal-height-columns" body-classes="px-0 pb-1" footer-classes="pb-2"
              id="basic-info-card" v-loading="loaders.card">
          <div slot="header" class="d-flex ">
            <img :class="{'d-none':!!nonSurgicalWeightLossDetail.is_pdf }" :src="nonSurgicalWeightLossDetail.picture" width="124" height="114" alt=""/>
            <div class="content-section-header-width">
              <div class="row justify-content-between ml-2 mr-0">
                <div class="d-inline-block content-section-heading-width">
                  <h1 class="contact-name ellipsis-overflow-text"
                      :title="nonSurgicalWeightLossDetail.name">
                    {{ nonSurgicalWeightLossDetail.name }}
                  </h1>
                </div>
                <div class="d-inline-block">
                  <p class="contact-status px-3 py-1 mt-1"
                     :class="nonSurgicalWeightLossDetail.status === 0 ? 'inactive' : 'active'">
                    {{ getStatus(nonSurgicalWeightLossDetail.status) }}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <h2 class="mt-3 pl-4 contact-detail-heading">BASIC INFO</h2>
          <div class="row pt-2 pl-4">
            <div class="col-6">
              <p class="contact-detail-text">Non Surgical Weight Loss Category</p>
              <p class="contact-detail-text">Non Surgical Weight Loss Sub Category</p>
            </div>
            <div class="col-6">
              <p class="black_p">{{
                  nonSurgicalWeightLossDetail.non_surgical_weight_loss_category ? nonSurgicalWeightLossDetail.non_surgical_weight_loss_category.name : ''
                }}</p>
              <p class="black_p">{{
                  nonSurgicalWeightLossDetail.non_surgical_weight_loss_sub_category ? nonSurgicalWeightLossDetail.non_surgical_weight_loss_sub_category.name : ''
                }} </p>
            </div>
          </div>
        </card>
      </div>
      <div class="col-6 content-section-card-width">
        <card class="no-border-card" body-classes="px-0 pb-1" footer-classes="pb-2" v-loading="loaders.card">
          <div class="px-4">
            <h2 class="mt-2 contact-detail-heading">Content</h2>
            <p class="mb-2 overflow-auto content-section-max-height" v-html="nonSurgicalWeightLossDetail.content"></p>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import BackButton from "@/components/Router/BackButton";
import moment from "moment";
export default {
  components: {BackButton},
  name: "ShowNonSurgicalWeightLoss",
  data() {
    return {
      loaders: {
        card: false,
      },
      apiParams: {
        nonSurgicalWeightLossId: this.$route.params.id,
      },
      nonSurgicalWeightLossDetail : {
        name : '',
        content : '',
        status : '',
        non_surgical_weight_loss_category:'',
        non_surgical_weight_loss_sub_category:'',
      },
    };
  },

  mounted() {
    this.getNonSurgicalWeightLossDetail();
  },
  methods: {
    getNonSurgicalWeightLossDetail() {
      let vm = this;
      vm.loaders.card = true;
      axios.get(vm.$store.getters.getBaseUrl + `/api/content/non-surgical-weight-loss/${vm.apiParams.nonSurgicalWeightLossId}`)
        .then((response) => {
          vm.$store.commit('toggleSessionCheck', true)
          vm.$store.commit('updateSessionTime', 0)
          vm.loaders.card = false;
          vm.nonSurgicalWeightLossDetail = response.data.data;
        }).catch(error => {
        vm.loaders.card = false;
        console.log(error.response)
      }).finally(() => {
        vm.loaders.card = false;
      });
    },

    getStatus(status) {
      if (status === 0) {
        return 'Inactive'
      }
      return 'Active';
    },

  },
  computed: {


  },
  watch : {
  }
}
</script>

<style scoped>
.contact-name {
  text-align: left;
  font: normal normal 600 25px/34px Open Sans;
  letter-spacing: 0;
  color: #32325D;
  opacity: 1;
}

#contact-email {
  text-align: left;
  font: normal normal 600 17px/23px Open Sans;
  letter-spacing: 0;
  color: #8898AA;
  opacity: 1;
}

.contact-status {
  box-shadow: 0px 3px 2px #E9ECEF0D;
  border-radius: 20px;
  opacity: 1;
  text-align: left;
  font: normal normal 600 12px/17px Open Sans;
  letter-spacing: 0.48px;
  color: #FFFFFF;
}

.active {
  background: #94B527 0 0 no-repeat padding-box;
}

.inactive {
  background: #BB2255 0 0 no-repeat padding-box;
}

.contact-detail-heading {
  text-align: left;
  font: normal normal bold 16px/22px Open Sans;
  letter-spacing: 0;
  color: #172B4D;
  text-transform: uppercase;
  opacity: 1;
}

.contact-detail-text {
  text-align: left;
  font: normal normal normal 14px/19px Open Sans;
  letter-spacing: 0;
  color: #8898AA;
  opacity: 1;
}
</style>
