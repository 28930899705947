<template>
  <div class="ml-4 mr-4 mt-4">
    <div class="forms" v-loading="loaders">
      <validation-observer v-slot="{handleSubmit}" ref="formValidator">
        <form role="form" type="multipart" @submit.prevent="handleSubmit(onSubmit)">
          <card type="default" header-classes="bg-transparent">
            <div class="row">
              <div class="col-lg-6 col-md-6">
                <base-input
                  class=""
                  :rules="{required: true}"
                  name="Name"
                  label="Name*" v-model="request.nonSurgicalWeightLossForm.name"
                  placeholder=" Name">
                </base-input>
              </div>
              <div class="col-lg-6 col-md-6">
                <label class="form-control-label">Category</label>
                <el-select
                  class="w-100"
                  v-model="request.nonSurgicalWeightLossForm.category"
                  placeholder="Select Category"
                  @change="onChangeNonSurgicalWeightLossCategory"
                >
                  <el-option
                    class="select-danger"
                    :value="0"
                    label="Select Category"
                    key="0">
                  </el-option>
                  <el-option
                    v-for="option in response.categories"
                    class="select-danger"
                    :value="option.id"
                    :label="option.name"
                    :key="option.id">
                  </el-option>
                </el-select>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6 col-md-6">
                <label class="form-control-label">Sub Category</label>
                <el-select
                  class="w-100 mb-3"
                  v-model="request.nonSurgicalWeightLossForm.subCategory"
                  placeholder="Select Sub Category"
                >
                  <el-option
                    class="select-danger"
                    :value="0"
                    label="Select Sub Category"
                    key="0">
                  </el-option>
                  <el-option
                    v-for="option in response.subCategories"
                    class="select-danger"
                    :value="option.id"
                    :label="option.name"
                    :key="option.id">
                  </el-option>
                </el-select>
              </div>
              <div class="col-lg-6 col-md-6">
                <base-input label="Status">
                  <el-select
                    class="w-100"
                    label="Status"
                    v-model="request.nonSurgicalWeightLossForm.status">
                    <el-option
                      v-for="option in dropdowns.status"
                      class="select-danger"
                      :value="option.value"
                      :label="option.label"
                      :key="option.label">
                    </el-option>
                  </el-select>
                </base-input>
              </div>
            </div>
            <div class="row">
              <div class="col-6 row m-0 mb-4">
                <label class="form-control-label w-100">PDF</label>
                <div class="col-md-6 pl-0 pr-2">
                  <div class="radio-button-card pl-3">
                    <base-radio name="v1" v-model="selectedValue" value="pdfValues.r1.value" class="base-radio-button pt-3" selected>
                      <span class="base-radio-text">Yes</span>
                    </base-radio>
                  </div>
                </div>
                <div class="col-md-6 pr-0 pl-2">
                  <div class="radio-button-card pl-3">
                    <base-radio name="v2" v-model="selectedValue"  value="pdfValues.r2.value" class="base-radio-button pt-3">
                      <span class="base-radio-text">No</span>
                    </base-radio>
                  </div>
                </div>

              </div>
              <div class="col-lg-6 col-md-6 d-flex justify-content-end align-items-center" v-if="selectedValue=='v2'">
                <div class="img-upload"
                     :class="{'w-100':!imageExist}"
                >
                  <label class="form-control-label" for="profileImage">Picture</label>
                  <input type="file" id="profileImage" ref="profileImage" accept="image/png, image/gif, image/jpeg"
                         class="form-control file-upload" @change="onImageChange">
                  <span class="font-size-12px">Recommended image size 500x300 px. Must be less than 500kb.</span>
                </div>
                <el-image
                  v-loading="loader.image"
                  class="img-size pl-2"
                  :class="{'d-none':!imageExist}"
                  :src="request.nonSurgicalWeightLossForm.picturePreview"
                  @load="handleLoad"
                  @error="handleLoad"
                />
              </div>
                <div class="col-lg-6 col-md-6" v-if="selectedValue=='v1'">
                  <label class="form-control-label" for="PDF">PDF</label>
                  <input type="file" id="pdf" ref="pdf" accept="application/pdf" class="form-control file-upload" @change="onFileChange">
                  <span class="font-size-12px">File size should be 20Mb or less.</span>
                </div>
            </div>
            <div class="row mt-3" v-if="selectedValue=='v2'">
              <div class="col equal-height-columns">
                <label  class="form-control-label">Content*</label>
                <editor
                  v-model="request.nonSurgicalWeightLossForm.content"
                  :api-key=editorApiKey
                  :init=editorOption
                />
              </div>
            </div>
          </card>
        </form>
        <base-button
          @click="onSubmit('nonSurgicalWeightLossForm')"
          class="btn-primary-action mb-4 br-4 pr-5 pl-5"
          native-type="submit"
          type="submit">
          {{ buttonText }}
        </base-button>
      </validation-observer>
    </div>
  </div>
</template>

<script>
import {store} from "@/vuex/store";
import Helper from "@/util/globalHelpers";

export default {
  name: "NonSurgicalWeightLossForm",
  props: ['categories', 'subCategories', 'nonSurgicalWeightLossItemToEdit', 'loaders', 'buttonText'],

  data() {
    return {
      editorApiKey: process.env.VUE_APP_EDITOR_API_KEY,
      editorOption: store.state.toolbarOptions,
      loader: {
        image: false,
      },
      modals: {
        detailsModal: false,
        checklistItemsModal: false,
      },
      request: {
        nonSurgicalWeightLossForm: {
          name: '',
          category: 0,
          subCategory: 0,
          picture: '',
          picturePreview: '',
          status: 1,
          content: '',
          pdfFile:'',
          pdf:0,
        },
      },
      response: {
        categories: [],
        subCategories: [],
      },
      dropdowns: {
        status: [
          {
          value: 1,
          label: 'Active'
          },
          {
            value: 0,
            label: 'Inactive',
          },
        ],
      },
      pdfValues: {
        v1: {value: 1, label: 'Yes'},
        v2: {value: 0, label: 'No'},
      },
      selectedValue:'v2',
    }
  },
  mounted() {
    this.fetchNonSurgicalWeightLossCategories();
  },
  watch: {
    nonSurgicalWeightLossItemToEdit(newVal) {
      this.request.nonSurgicalWeightLossForm.name = newVal.name ?? '';
      this.request.nonSurgicalWeightLossForm.category = newVal.non_surgical_weight_loss_category_id;
      this.request.nonSurgicalWeightLossForm.subCategory = newVal.non_surgical_weight_loss_sub_category_id;
      this.request.nonSurgicalWeightLossForm.content = newVal.content ?? '';
      this.request.nonSurgicalWeightLossForm.status = newVal.status;
      this.request.nonSurgicalWeightLossForm.pdf = newVal.is_pdf;
      this.selectedValue = newVal.is_pdf == 1 ? 'v1' : 'v2' ;
      if (newVal.hasOwnProperty('non_surgical_weight_loss_category')) {
        this.fetchNonSurgicalWeightLossSubCategories(newVal.non_surgical_weight_loss_category_id);
      }
      this.loader.image = true
      this.request.nonSurgicalWeightLossForm.picturePreview = newVal.picture;
    }
  },
  methods: {
    handleLoad() {
      this.loader.image = false
    },

    onFileChange(e){
      if (e.target.files[0].size > 20000000) {
        this.$notify.error({
          title: 'Error',
          message: 'The pdf file size can not be greater than 20Mb.'
        })
        this.$refs.pdf.value= null
        return
      }
      this.request.nonSurgicalWeightLossForm.pdfFile = e.target.files[0];
    },

    onImageChange(e){
      this.request.nonSurgicalWeightLossForm.picture = e.target.files[0];
    },

    handleKeyPressForEditor(value) {
      this.request.nonSurgicalWeightLossForm.content = value;
    },

    onChangeNonSurgicalWeightLossCategory(nonSurgicalWeightLossCategory){
      if (nonSurgicalWeightLossCategory == 0){
        this.response.subCategories = [];
        this.request.nonSurgicalWeightLossForm.subCategory = 0;
      }
      else {
        this.request.nonSurgicalWeightLossForm.subCategory = 0;
        this.fetchNonSurgicalWeightLossSubCategories(nonSurgicalWeightLossCategory);
      }
    },

    fetchNonSurgicalWeightLossCategories() {
      let vm = this;
      axios.get(this.$store.getters.getBaseUrl + '/api/content/non-surgical-weight-loss-categories/all')
        .then((response) => {
          vm.$store.commit('toggleSessionCheck', true)
          vm.$store.commit('updateSessionTime', 0)
          vm.response.categories = response.data.data;
        })
        .catch((error) => console.error(error.response));
    },

    fetchNonSurgicalWeightLossSubCategories(id) {
      let vm = this;
      if (id){
        axios.get(this.$store.getters.getBaseUrl + '/api/content/non-surgical-weight-loss-sub-categories/by-category-id?categoryId=' + id)
          .then((response) => {
            vm.$store.commit('toggleSessionCheck', true)
            vm.$store.commit('updateSessionTime', 0)
            vm.response.subCategories = response.data.data;
          })
          .catch((error) => console.error(error.response));
      }
    },


    async onSubmit(formName) {
      const isNonSurgicalWeightLossFormValid = await this.$refs['formValidator'].validate();

      if (isNonSurgicalWeightLossFormValid) {
        this.request.nonSurgicalWeightLossForm.content = Helper.addCustomStylingToContent(this.request.nonSurgicalWeightLossForm.content)
        if (this.$route.name === 'StoreNonSurgicalWeightLoss') {
          this.request.nonSurgicalWeightLossForm.pdf = this.pdfValues[this.selectedValue].value;
          console.log('store',this.request.nonSurgicalWeightLossForm);
          this.$emit('storeNonSurgicalWeightLoss', this.request.nonSurgicalWeightLossForm);
        } else if (this.$route.name === 'EditNonSurgicalWeightLoss') {
          this.request.nonSurgicalWeightLossForm.pdf = this.pdfValues[this.selectedValue].value;
          console.log('edit',this.request.nonSurgicalWeightLossForm);
          this.$emit('updateNonSurgicalWeightLoss', this.request.nonSurgicalWeightLossForm);
        } else {
          console.log('Hello Else World');
        }
      }
      else {
        this.$notify.error({
          title: 'Validation Error',
          message: 'Please enter values in the form'
        });
      }
    },
  },
  computed: {
    getContentValue() {
      return this.request.nonSurgicalWeightLossForm.content;
    },

    imageExist() {
      return this.request.nonSurgicalWeightLossForm.picturePreview !== ''
        && this.request.nonSurgicalWeightLossForm.picturePreview
        && this.request.nonSurgicalWeightLossForm.picture === ''
    }
  },
}
</script>

<style scoped>
.radio-button-card{
  height: 57px;
  border-radius: 4px;
  border: 1px solid #DEE2E6;
}

.base-radio-text {
  font: normal normal normal 14px/19px Open Sans;
  color: #525F7F;
}

.form-control.file-upload{
  height: calc(1.5em + 1.25rem + 13px);
  padding-top: calc(0.75rem + 3px);
}

.img-size{
  width: 120px;
  height: 100px;
  display: inline-block;
}

.img-upload{
  width: calc(100% - 120px);
  display: inline-block;
}
</style>
